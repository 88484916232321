import React from "react"
import { graphql } from "gatsby"

import MainLayout from "../components/main-layout"
import SEO from "../components/seo"

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <MainLayout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <div className="container pt-12 mx-auto h-full">
          <h1 className="text-5xl font-black mt-16 mb-8">Not Found</h1>
          <p className="mb-8">
            You just hit a route that doesn&#39;t exist... the sadness.
          </p>
        </div>
      </MainLayout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
